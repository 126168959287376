import React from "react";
import {graphql, useStaticQuery} from "gatsby";

import Layout from "../../components/layout";
import ImageCaptionSection from "../../components/imageCaptionSection";
import HelpMethodsSection from "../../components/helpMethodsSection";

function GovernmentSchemesPage(props) {
  const data = useStaticQuery(graphql`
    query GovernmentSchemesPageQuery {
      markdownRemark(frontmatter: {templateKey: {eq: "government-schemes"}}) {
        frontmatter {
          imageCaptionSection {
            subTitle
            title
            imageCaptionBackground
          }
        }
      }
      allMarkdownRemark(
        filter: {fileAbsolutePath: {regex: "/pages/government-schemes/.*md/"}}
        sort: {fields: frontmatter___title, order: ASC}
      ) {
        edges {
          node {
            frontmatter {
              title
            }
            html
          }
        }
      }
    }
  `);
  const {title, subTitle, imageCaptionBackground} = data.markdownRemark.frontmatter.imageCaptionSection;
  const govtSchemes = data.allMarkdownRemark.edges.map(govtScheme => ({
    ...govtScheme.node.frontmatter,
    html: govtScheme.node.html,
  }));

  const [selectedScheme, setSelectedScheme] = React.useState(0);
  const contentRef = React.useRef(null);

  const updateScheme = newScheme => {
    setSelectedScheme(newScheme);
    if (contentRef.current) {
      contentRef.current.scrollIntoView();
      // Wait for a short time to let scrollIntoView finish, then apply the offset
      setTimeout(() => {
        window.scrollBy(0, -200);
      }, 100);
    }
  }
  
  return (
    <Layout activeLink="/stay-informed" pageTitle={title} pageDescription={subTitle} {...props}>
      <ImageCaptionSection title={title} subTitle={subTitle} imageAlt={`${title} - ${subTitle}`}
                           imageSrc={imageCaptionBackground}/>

      <div className="container mx-auto p-4 mt-10">
        <div className="flex flex-col md:flex-row">
          {/* List of schemes */}
          <ul className="mb-4 md:mb-0 md:w-1/3">
            {govtSchemes.map((scheme, index) => (
              <li key={index} className="mb-2">
                <button
                  className={`md:text-xl text-red-600 hover:underline
                  ${index === selectedScheme ? "font-bold underline" : ""}`}
                  onClick={() => updateScheme(index)}
                >
                  {scheme.title}
                </button>
                <hr className="my-2"/>
              </li>
            ))}
          </ul>

          {/* Display selected scheme content */}
          <div className="md:w-2/3 md:pl-4" ref={contentRef}>
            {selectedScheme !== null && (
              <div className="text-justify markdown-html mb-10"
                   dangerouslySetInnerHTML={{__html: govtSchemes[selectedScheme]?.html || ""}}/>
            )}
          </div>

        </div>
      </div>

      <HelpMethodsSection/>
    </Layout>
  );
}

export default GovernmentSchemesPage;
